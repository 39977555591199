import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../../services/api.service';
import Moralis from 'moralis';
import Web3 from "web3";
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';

import Chart from "chart.js";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
  chartExample3
} from "../../variables/charts";
import * as moment from 'moment';
import { WalletService } from 'src/app/services/wallet.service';

//import { CoinGeckoClient } from 'coingecko-api-v3';
//const CoinGecko = require('coingecko-api');

declare var myExtObject: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  provider:any;
  
  //Display
  isCollapsed = true;
  loggedUser:any;
  showWallet:string = '';
  showWalletLogin:boolean = false;
  showStakeManage:boolean = false;
  showFundManage:boolean = false;
  wallet:any;
  showLoading:boolean=false;
  loadingStatus:string='';
  displayStakeConfirm:boolean = false;
  displayUnstakeConfirm:boolean = false;
  control:string = 'stake';
  error:string='';
  //Charts
  datasets: any;
  data: any;
  salesChart;
  months:string[]= ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

  //Web3 data
  marketCap:number = 0;
  supply:number = 0;
  holders:number=0;
  network:boolean = false;
  tokens: any[];
  shineBalance:number;
  shineData:any[];
  stakePeriod:number = 14;
  fundAmount:number = 0;
  stakeAmount:number = 0;
  unstakeAmount:number = 0;
  totalTokens:number = 0;
  silver:number=0;
  gold:number=0;
  diamond:number=0;
  claimable:number=0;
  totalReward:string = '';
  silverUnstakable:Date= new Date();
  goldUnstakable:Date= new Date();
  diamondUnstakable:Date = new Date();
  silverDaysRem:number=0;
  goldDaysRem:number=0;
  diamondDaysRem:number=0;
  totalStaked:number=0;
  userTotalRewards:number=0;
  lastWeekRewards:number=0;
  totalSilverStaked:number=0;
  totalGoldStaked:number=0;
  totalDiamondStaked:number=0;
  totalSilverStakers:number=0;
  totalGoldStakers:number=0;
  totalDiamondStakers:number=0;
  totalClaimed:number=0;
  TLV:number=0;
  price:any;
  bnbprice:any;
  silverApr:number=0;
  goldApr:number=0;
  diamondApr:number=0;
  silverApy:number = 0;
  goldApy:number = 0;
  diamondApy:number = 0;
  providerName:string;
  mobile:boolean = false;
  owner:boolean = false;
  web3:any=null;
  constructor(@Inject(DOCUMENT) private document: Document, 
  private service:ApiService, private router: Router, private web3service: WalletService) {}

  
  ngOnInit(): void {
    this.getChart();
/*
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobile = true;
     }

    Moralis.start({
      appId: "3aiNivDwJfAPIaj00ZU8llNMISKKFku07cHxjvWb",//environment.moralisAppId,
      serverUrl:"https://hjjjmpwraerj.usemoralis.com:2053/server"// environment.moralisUrl,
    })
      .then(() => console.info('Moralis has been initialised.'))
      .finally(() => {
          this.loggedUser=Moralis.User.current();
          if(this.loggedUser){
            this.setLoggedInUser(Moralis.User.current())
          }
      });

      Moralis.onChainChanged(function(){
        window.location.reload();
      });

      Moralis.onConnect(function(){
        this.loggedUser=Moralis.User.current();
      })

      Moralis.onDisconnect(function(accounts) {
        window.location.reload();
      });
      this.getChart();

      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === 'hidden' && this.iOS()) {
          window.localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
        }
      });*/
  }

  iOS() {
    return (
      [
                    'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  getChart(){
    this.service.getCoinData().subscribe((res)=>{
      console.log(res)
      this.shineData = res['prices'] as any[];
      this.initSalesChart(this.shineData)

    },
    err=>{
      console.log(err)
    })

    this.service.getTokenHolders().subscribe((res)=>{
      if(!res) return;
      this.holders = res['data']['items'].length;
  
   
    },
    err=>{

    })
    
  }

  clearModal(){
    this.showStakeManage = false;
    this.showLoading = false;
    this.showFundManage=false;
  }

  controlModal(desc:string){
    this.clearModal();
    if(desc==='stake'){
      this.showStakeManage = true;
    }
    else if(desc==='loading'){
      this.showLoading = true;
    }
    else if(desc === 'fund'){
      this.showFundManage = true;
    }
  
  }

  async getCoinData(){
    try{
     
      let bnb = await this.service.getBNBCoinData();
      this.bnbprice = Number(bnb['binancecoin']['usd']);
      let shine = await this.service.getShinePrice();
      this.price = Number(shine['shinemine']['usd']);
      let supply = await this.service.getCoinSupply();
      this.supply = Number(this.web3.utils.fromWei(supply['result']));
      this.marketCap = this.price * this.supply;
      
    }
    catch(error){
      console.log(error)
    }
  }


  async startLogin(){
    let data = await this.web3service.initWeb3();
    if(!data.error){
      this.wallet = data.wallet;
      this.web3 = data.web3;
      this.showWallet = this.wallet.slice(0,15) + "...";
      this.owner = await this.web3service.getOwner();
      await this.getCoinData();
      await this.runDataPull();
    }
   
  }

  async runDataPull(){
    this.loadingStatus = 'Please Give Us a Moment To Collect Your Information...'
    this.controlModal('loading');
    try {
      this.shineBalance = await this.web3service.getShineBalance();
      let epoch = await this.web3service.getCurrentEpoch();
      let stakeData = await this.web3service.getStakedBalances();
      this.silver = stakeData?.silver;
      if(this.silver){
        this.silverUnstakable = new Date(Number(stakeData?.silverTime.toString()) * 1000);
        this.silverDaysRem = moment(this.silverUnstakable).diff(moment(new Date()), 'days');
      }
      this.gold = stakeData?.gold;
      if(this.gold){
        this.goldUnstakable = new Date(Number(stakeData?.goldTime.toString()) * 1000);
        this.goldDaysRem = moment(this.goldUnstakable).diff(moment(new Date()), 'days');
      }
      this.diamond = stakeData?.diamond;
      if(this.diamond){
        this.diamondUnstakable = new Date(Number(stakeData?.diamondTime.toString()) * 1000);
        this.diamondDaysRem = moment(this.diamondUnstakable).diff(moment(new Date()), 'days');
      }
      let stakeAnalytics = await this.web3service.getStakeAnalytics();
      this.totalStaked = stakeAnalytics?.totalStaked;
      this.totalSilverStaked = stakeAnalytics?.totalSilverStaked;
      this.totalGoldStaked = stakeAnalytics?.totalGoldStaked;
      this.totalDiamondStaked = stakeAnalytics?.totalDiamondStaked;
      this.totalSilverStakers = stakeAnalytics?.totalSilverStakers;
      this.totalGoldStakers = stakeAnalytics?.totalGoldStakers;
      this.totalDiamondStakers = stakeAnalytics?.totalDiamondStakers;
      let userClaimData = await this.web3service.getUserClaimData(epoch);
      this.totalClaimed = userClaimData?.totalClaimed;
      this.claimable = userClaimData?.claimable;
      this.lastWeekRewards = userClaimData?.lastWeekRewards;
      

      
      let apCalcsData = await this.web3service.getApyCalcs(this.price,stakeAnalytics.totalStaked, this.bnbprice, userClaimData.lastWeekRewards);
      
        this.TLV=apCalcsData?.TLV;
        this.diamondApr=apCalcsData?.diamondApr;
        this.diamondApy=apCalcsData?.diamondApy;
        this.goldApr=apCalcsData?.goldApr;
        this.goldApy=apCalcsData?.goldApy;
        this.silverApr=apCalcsData?.silverApr;
        this.silverApy=apCalcsData?.silverApy;
      
        this.loadingStatus = ''
        this.clearModal();
    }
    catch(err){
      console.log(err)
    }
  }

  async startLogout(){
    let data = await this.web3service.logout();
    this.wallet = '';
  }

  initSalesChart(sales){
    var days = [];
    var totals =[];
    sales.forEach(x=>{
      var date = new Date(x[0]);
      days.push(this.months[date.getMonth()]+' '+date.getDate());
      totals.push(Number(x[1]).toFixed(7));
    });
    var chartSales = document.getElementById("chart-sales");
    var data = {
      labels: days,
      datasets: [
        {
          label: "ShineMine Price",
          data: totals
        }
      ]
    }
    this.salesChart = new Chart(chartSales, {
      type: "line",
      options: chartExample2.options,
      data: data
    })
  }

  setMax(type:string){
    if(type === 'stake'){
      this.stakeAmount = Math.floor(Number(this.shineBalance));
    }
    else{
      let pool = this.getPeriodString();
      this.unstakeAmount = Math.floor(Number(this.unstakableByPeriod(pool)));
    }
    
  }

  getPeriodString(){
    if(this.stakePeriod === 180){
      return 'DIAMOND';
    }
    else if(this.stakePeriod === 90){
      return 'GOLD';
    }
    else{
      return 'SILVER';
    }
  }

  setPeriod(period:number){
    this.error='';
    this.displayStakeConfirm = false;
    this.stakePeriod = period;
  }

  setControl(cont:string){
    this.error='';
    this.displayStakeConfirm = false;
    this.control = cont;
  }

  getUnstakable(){
    let total = 0;
    total += this.unstakableByPeriod('DIAMOND');
    total += this.unstakableByPeriod('GOLD');
    total += this.unstakableByPeriod('SILVER');
    return total;
  }

  unstakableByPeriod(period:string){
    if(period==="DIAMOND" && moment(this.diamondUnstakable).isSameOrBefore(moment(new Date()))){
       return this.diamond;
    }

    if(period==="GOLD" && moment(this.goldUnstakable).isSameOrBefore(moment(new Date()))){
       return this.gold;
    }

    if(period==="SILVER" && moment(this.silverUnstakable).isSameOrBefore(moment(new Date()))){
      return this.silver;
    }
    return 0;
  }

  submitStake(){
    if(this.stakeAmount <= 0 ){
      this.error = 'Stake Amount Must Be Greater than 0.';
      return;
    }
    if(this.stakePeriod === 0 ){
      this.error = 'Stake Period Must Be Set.';
      return;
    }
    if( this.stakeAmount > this.shineBalance) {
      this.error = 'Stake Amount Cannot Be Greater Than Your Current SHINE Balance.';
      return;
    }
    this.displayStakeConfirm = true;
  }

  async confirmStake(){
    let pool = this.getPeriodString();
    this.loadingStatus = 'Processing your staking request. On Trust Wallet Mobile, Please go to your trust wallet to confirm. First time transactions will have approval then transaction sign.';
    this.controlModal('loading');
    try{
      let stakeAmountWei = this.web3.utils.toWei(this.stakeAmount.toString());
      this.loadingStatus = 'Please confirm your transaction. On Trust Wallet Mobile, Please go to your trust wallet to confirm.';
      let contract = this.web3service.getStakeContract();
      let shineContract = this.web3service.getShineContract();
      let allowForm= Number(this.web3.utils.fromWei(await shineContract.methods.allowance(this.wallet, environment.stakeContract).call()));
      if(allowForm <= 0) {
        //Unlimited
        let unlimit = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
        let approve = await shineContract.methods.approve(environment.stakeContract, unlimit).send({from: this.wallet})
        .on("transactionHash", async (hash) => {
          
          this.loadingStatus = 'Aprroval Transaction has been posted. Waiting for confirmation.';
        })
        .on("receipt", async(reciept) => {
          this.loadingStatus = 'Approval Transaction has completed.';
          //await this.runDataPull();
         
        })
        .on("error", async(error)=>{
          this.loadingStatus = error.message;
     
        });
        
      }


      let transaction = await contract.methods.stake(pool, stakeAmountWei).send({from: this.wallet})
      .on("transactionHash", async (hash) => {
          console.log('hash')
        this.loadingStatus = 'Transaction has been posted. Waiting for confirmation.';
      })
      .on("receipt", async(reciept) => {
        this.loadingStatus = 'Transaction has completed.';
        await this.runDataPull();
       
      })
      .on("error", async(error)=>{
        this.loadingStatus = error.message;
   
      });
      
    }
    catch(err){
      this.loadingStatus = err.message;
    }

  }

  submitUnstake(){
    let pool = this.getPeriodString();
    if(this.stakePeriod === 0) {
      this.error = 'Unstake Period Must Be Set.';
      return;
    }
    else if( this.unstakeAmount <= 0) {
      this.error = 'Amount Must Be Greater than 0.';
      return;
    }
    else if( this.unstakeAmount > this.unstakableByPeriod(pool)) {
      this.error = 'Amount Cannot Be Greater Than Your '+pool+' Unstakable Balance.';
      return;
    }
    else if(this.stakePeriod === 180){
      if(this.unstakableByPeriod('DIAMOND') <= 0){
        this.error = 'You do not have any diamond staked coins that are elgible to unstake.';
        return;
      }
    }
    else if(this.stakePeriod === 90){
      if(this.unstakableByPeriod('GOLD') <= 0){
        this.error = 'You do not have any gold staked coins that are elgible to unstake.';
        return;
      }
    }
    else{
      if(this.unstakableByPeriod('SILVER') <= 0){
        this.error = 'You do not have any silver staked coins that are elgible to unstake.';
        return;
      }
    }
    this.displayUnstakeConfirm = true;
  }

  async confirmUnstake(){
    let pool = this.getPeriodString();
    this.loadingStatus = 'Processing your request. On Trust Wallet Mobile, Please go to your trust wallet to confirm.';
    this.controlModal('loading');
    try{
      let unstakeAmountWei = this.web3.utils.toWei(this.unstakeAmount+'');
     
      this.loadingStatus = 'Please confirm your transaction. On Trust Wallet Mobile, Please go to your trust wallet to confirm.';
      let contract = this.web3service.getStakeContract();
      let transaction = await contract.methods.unstake(pool, unstakeAmountWei).send({from: this.wallet})
      .on("transactionHash", async (hash) => {
          
        this.loadingStatus = 'Transaction has been posted. Waiting for confirmation.';
      })
      .on("receipt", async(reciept) => {
        this.loadingStatus = 'Transaction has completed.';
        await this.runDataPull();
       
      })
      .on("error", async(error)=>{
        this.loadingStatus = error.message;
   
      });
      
    }
    catch(err){
      this.loadingStatus = err.message;
    }
  }

  async claimRewards(){
    this.loadingStatus = 'Processing your request. On Trust Wallet Mobile, Please go to your trust wallet to confirm.';
    this.controlModal('loading');
    try{
      this.loadingStatus = 'Please confirm your transaction. On Trust Wallet Mobile, Please go to your trust wallet to confirm.';
      let contract = this.web3service.getStakeContract();
      let transaction = await contract.methods.claimRewards().send({from: this.wallet})
      .on("transactionHash", async (hash) => {
          
        this.loadingStatus = 'Transaction has been posted. Waiting for confirmation.';
      })
      .on("receipt", async(reciept) => {
        this.loadingStatus = 'Transaction has completed.';
        await this.runDataPull();
       
      })
      .on("error", async(error)=>{
        this.loadingStatus = error.message;
   
      });
      
    }
    catch(err){
      this.loadingStatus = err.message;
    }
    
  }

  async submitFund(){
    if(this.fundAmount <= 0){
      this.error = 'Please enter a value greater than 0.';
      return;
    }
    this.loadingStatus = 'Processing your request. On Trust Wallet Mobile, Please go to your trust wallet to confirm.';
    this.controlModal('loading');
    try{

      if(!this.owner){
        this.loadingStatus = 'Transaction Failed. You are not authorized to perform this action.';
        return;
      }
      let contract = this.web3service.getStakeContract();
      this.loadingStatus = 'Please confirm your transaction. On Trust Wallet Mobile, Please go to your trust wallet to confirm.';
      let priceWei = this.web3.utils.toWei(this.fundAmount.toString());
      
      await contract.methods.startNewEpoch(priceWei, { value: priceWei }).send({from: this.wallet})
      .on("transactionHash", async (hash) => {
          
        this.loadingStatus = 'Transaction has been posted. Waiting for confirmation.';
      })
      .on("receipt", async(reciept) => {
        this.loadingStatus = 'Transaction has completed.';
        await this.runDataPull();
       
      })
      .on("error", async(error)=>{
        this.loadingStatus = error.message;
   
      });



    }
    catch(error){
      this.loadingStatus = 'Error Occured Completing The Transaction. Please Retry or Reach Out To Support.';
      console.log(error)
    }
  }

}


/*

async getStakeData(){
    /*this.loadingStatus = 'Please Give Us a Moment To Collect Your Information...'
    this.controlModal('loading');
    try{
      let ethers = Moralis.web3Library;
      let address = Moralis.account;
      await this.getProvider();
      //const shinecontract = new ethers.Contract( environment.shineContract, abi);
      let shineRead = await shinecontract.connect( this.provider ) as any;
      let balance = await shineRead.balanceOf(address);
      this.shineBalance = Number(ethers.utils.formatEther(balance));
      //const contract = new ethers.Contract( environment.stakeContract, stakeabi);
      const signer = this.provider.getSigner();

      let shineSign = await contract.connect( signer ) as any;

      //Gets User Data
      let balances = await shineSign.getUserStakeData(address);
      this.silver = Number(ethers.utils.formatEther(balances.silverAmount));
      let isOwner = await shineSign.owner();
      this.owner = isOwner.toLowerCase() === address.toLowerCase();
      this.gold = Number(ethers.utils.formatEther(balances.goldAmount));
      this.diamond = Number(ethers.utils.formatEther(balances.diamondAmount));
      if(this.silver > 0){
        let silverTime = await shineSign.getUnlockTime(address, 'SILVER');
        this.silverUnstakable = new Date(Number(silverTime.toString()) * 1000);
        this.silverDaysRem = moment(this.silverUnstakable).diff(moment(new Date()), 'days');
      }
      if(this.gold > 0){
        let goldTime = await shineSign.getUnlockTime(address, 'GOLD');
        this.goldUnstakable = new Date(Number(goldTime.toString()) * 1000);
        this.goldDaysRem = moment(this.goldUnstakable).diff(moment(new Date()), 'days');
      }
      if(this.diamond > 0){
        let diamondTime= await shineSign.getUnlockTime(address, 'DIAMOND');
        this.diamondUnstakable = new Date(Number(diamondTime.toString()) * 1000);
        this.diamondDaysRem = moment(this.diamondUnstakable).diff(moment(new Date()), 'days');
      }
      let epochUn = await shineSign.getCurrentEpoch();
      let epoch = Number(epochUn.toString());
    
      //Total Staked Coins All Users
      let totalStakedUnformated = await shineSign.getTotalStaked();
      this.totalStaked = Number(ethers.utils.formatEther(totalStakedUnformated));
      if(this.totalStaked !== 0){
        let totalSilverStakedUnfor = await shineSign.totalSilverStaked();
        this.totalSilverStaked = Number(ethers.utils.formatEther(totalSilverStakedUnfor));
        
        let totalGoldStakedUnfor = await shineSign.totalGoldStaked();
        this.totalGoldStaked = Number(ethers.utils.formatEther(totalGoldStakedUnfor));
        
        let totalDiamondStakedUnfor = await shineSign.totalDiamondStaked();
        this.totalDiamondStaked = Number(ethers.utils.formatEther(totalDiamondStakedUnfor));
      }
      
      //Gets number of user currently staking by tier
      let totalSilverUn = await shineSign.getPoolStakers('SILVER')
      this.totalSilverStakers = Number(totalSilverUn.toString());
      let totalGoldUn = await shineSign.getPoolStakers('GOLD')
      this.totalGoldStakers = Number(totalGoldUn.toString());
      let totalDiamondUn = await shineSign.getPoolStakers('DIAMOND')
      this.totalDiamondStakers = Number(totalDiamondUn.toString());


      //User Claim Data
      let tClaim = await shineSign.totalClaimedRewardsUser(address);
      this.totalClaimed = Number(ethers.utils.formatEther(tClaim));
      let totalPoints = 0;
      let rewardsPerPoint = 0;
      let previous = epoch > 0 ? epoch - 1 : epoch;
 
      if(previous >= 0){
        let lastWeekRewardsUnfor = await shineSign.getRewardsPerPointEpoch(previous);
        rewardsPerPoint = Number(ethers.utils.formatUnits(lastWeekRewardsUnfor, 9));
        let claimB = await shineSign.getUserUnclaimedRewards(address);
        this.claimable = Number(ethers.utils.formatEther(claimB));
        let epochForm = ethers.utils.parseEther(previous.toString())
        let totalPointsUn = await shineSign.getTotalPointsEpoch(previous);
        totalPoints = Number(ethers.utils.formatUnits(totalPointsUn,9 ));
        this.lastWeekRewards = totalPoints * rewardsPerPoint;

      }
    
      //Calc Apr and Apy
      this.TLV = this.price * this.totalStaked;
      let bnb = (this.price/this.bnbprice);
      //let bnb = 329;
      let silverRewardPoint = await shineSign.silverReward();
 
      let goldRewardPoint = await shineSign.goldReward();

      let diamondRewardPoint = await shineSign.diamondReward();
      let totalRewardPoints = Number(silverRewardPoint.toString()) + Number(goldRewardPoint.toString()) + Number(diamondRewardPoint.toString());

      this.silverApr =this.lastWeekRewards === 0 ? 0 :((((this.lastWeekRewards*this.bnbprice)*(Number(silverRewardPoint.toString())/totalRewardPoints))/(this.totalStaked*this.price)))*52
      //this.silverApr = this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards*this.bnbprice)*(Number(silverRewardPoint.toString())/totalRewardPoints))/(this.totalStaked*this.price));
      //this.silverApr = this.lastWeekRewards === 0 ? 0 :((((this.lastWeekRewards * (Number(silverRewardPoint.toString())/totalRewardPoints)))/(this.totalSilverStaked*bnb)))*100;

      this.goldApr =this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards*this.bnbprice)*((Number(goldRewardPoint.toString())+Number(silverRewardPoint.toString()))/totalRewardPoints))/(this.totalStaked*this.price))*52;
      //this.goldApr = this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards * (Number(goldRewardPoint.toString())/totalRewardPoints)))/(this.totalGoldStaked*bnb));
      this.diamondApr = this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards*this.bnbprice)*((Number(goldRewardPoint.toString())+Number(silverRewardPoint.toString())+Number(diamondRewardPoint.toString()))/totalRewardPoints))/(this.totalStaked*this.price))*52;
      //this.diamondApr = this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards * (Number(diamondRewardPoint.toString())/totalRewardPoints)))/(this.totalDiamondStaked*bnb));
      this.silverApy = Math.pow((1 + (this.silverApr/52)), 52) - 1;
      this.goldApy = Math.pow((1 + (this.goldApr/52)), 52) - 1;
      this.diamondApy = Math.pow((1 + (this.diamondApr/52)), 52) - 1;
      this.loadingStatus = ''
      this.clearModal();
    }
    catch(error){
      console.log(error)
      this.loadingStatus = 'Error Occured Getting Your Data. Please Refresh the page.';
      
    }
    
  }

  */