import { Injectable  } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ToastService } from 'angular-toastify';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import { environment } from 'src/environments/environment.prod';
import { abi as abi } from "../contracts/prod/shine.prod";
import { abi as stakeabi } from "../contracts/prod/stake.prod";
@Injectable({
  providedIn: 'root'
})
export class WalletService {
  provider:any;
  web3:any;
  web3Modal:any;
  wallet:any;
  chain="bsc";
  chainId = 56;
  shineTokenContract=null;
  shineStakeContract=null;
  constructor(private router:Router, private toast:ToastService) {
   
  }

  async initWeb3(){

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "68bbfa6dd6594f328012419c5b654b2f" // required
        }
      }
    };
    
    this.web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    try {
      this.provider = await this.web3Modal.connect();
      this.web3  = new Web3(this.provider);
      let network = await this.web3.eth.net.getNetworkType();
   

      //Display warning if on the wrong network
      if(network !== this.chain){
        this.toast.info("Please switch to the BSC network.");
        await this.web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(this.chainId) }]
        });
        network = await this.web3.eth.net.getNetworkType();
      
        //return;
      }

      let accounts = await this.web3.eth.getAccounts();
      
      let walletSet = accounts[0];
      this.wallet = walletSet;
      this.shineStakeContract = new this.web3.eth.Contract(stakeabi, environment.stakeContract);
      this.shineTokenContract = new this.web3.eth.Contract(abi, environment.shineContract);
      
      return {wallet:walletSet, web3:this.web3};
    }
    catch(err){
      console.log(err)
      this.toast.error(err.message);
      return {error:true,message:err.message};
    }
  }

  async logout(){
    try {
      this.web3Modal.clearCachedProvider();
      localStorage.removeItem('code');
      return {};
    }
    catch(err){
      return {error:true,message:err.message};
    }
  }

  getShineContract(){
    return this.shineTokenContract;
  }

  getStakeContract(){
    return this.shineStakeContract;
  }

  getWeb3(){
    return this.web3;
  }

  async getShineBalance(){
    try {
      let balance = await this.shineTokenContract.methods.balanceOf(this.wallet).call();
      return  Number(this.web3.utils.fromWei(balance));
    }
    catch(err){
      console.log(err)
    }
  }

  async getStakedBalances(){
    try {
      let balance = await this.shineStakeContract.methods.getUserStakeData(this.wallet).call();
      let silverTime = await this.shineStakeContract.methods.getUnlockTime(this.wallet, 'SILVER').call();
      let goldTime = await this.shineStakeContract.methods.getUnlockTime(this.wallet, 'GOLD').call();
      let diamondTime = await this.shineStakeContract.methods.getUnlockTime(this.wallet, 'DIAMOND').call();
      return {
        silver:Number(this.web3.utils.fromWei(balance.silverAmount)),
        gold:Number(this.web3.utils.fromWei(balance.goldAmount)),
        diamond:Number(this.web3.utils.fromWei(balance.diamondAmount)),
        silverTime:silverTime,
        goldTime:goldTime,
        diamondTime:diamondTime
      }
      
    }
    catch(err){
      console.log(err)
    }
  }

  async getOwner(){
    try {
      let owner = await this.shineStakeContract.methods.owner().call();
      return owner.toLowerCase() === this.wallet.toLowerCase();
    }
    catch(err){
      console.log(err)
    }
  }

  async getCurrentEpoch(){
    try {
      let epochUn = await this.shineStakeContract.methods.getCurrentEpoch().call();
      return Number(epochUn.toString());
    }
    catch(err){
      console.log(err)
    }
  }

  async getStakeAnalytics(){
    try {
      //Total Staked Coins All Users

      let totalStaked = Number(this.web3.utils.fromWei(await this.shineStakeContract.methods.getTotalStaked().call()));
      let totalSilverStaked = 0;
      let totalDiamondStaked = 0;
      let totalGoldStaked = 0
      if(totalStaked !== 0){
        totalSilverStaked =Number(this.web3.utils.fromWei(await this.shineStakeContract.methods.totalSilverStaked().call()));
        totalGoldStaked =Number(this.web3.utils.fromWei(await this.shineStakeContract.methods.totalGoldStaked().call()));
        totalDiamondStaked =Number(this.web3.utils.fromWei(await this.shineStakeContract.methods.totalDiamondStaked().call()));
        
      }
      //Gets number of user currently staking by tier
      let totalSilverUn = await this.shineStakeContract.methods.getPoolStakers('SILVER').call()
      let totalSilverStakers = Number(totalSilverUn.toString());
      let totalGoldUn = await this.shineStakeContract.methods.getPoolStakers('GOLD').call()
      let totalGoldStakers = Number(totalGoldUn.toString());
      let totalDiamondUn = await this.shineStakeContract.methods.getPoolStakers('DIAMOND').call()
      let totalDiamondStakers = Number(totalDiamondUn.toString());
      return {
        totalStaked:totalStaked,
        totalSilverStaked:totalSilverStaked,
        totalGoldStaked:totalGoldStaked,
        totalDiamondStaked:totalDiamondStaked,
        totalSilverStakers:totalSilverStakers,
        totalGoldStakers:totalGoldStakers,
        totalDiamondStakers:totalDiamondStakers
      }
    }
    catch(err){
      console.log(err);
    }
  }

  async getUserClaimData(epoch){
    try{
      //User Claim Data
      let totalClaimed = Number(this.web3.utils.fromWei(await this.shineStakeContract.methods.totalClaimedRewardsUser(this.wallet).call()));
      let totalPoints = 0;
      let rewardsPerPoint = 0;
      let previous = epoch > 0 ? epoch - 1 : epoch;
      let claimable = 0;
      let lastWeekRewards=0;
      if(previous >= 0){
        rewardsPerPoint = Number(this.web3.utils.fromWei(await this.shineStakeContract.methods.getRewardsPerPointEpoch(previous).call()));
       
        claimable = Number(this.web3.utils.fromWei(await this.shineStakeContract.methods.getUserUnclaimedRewards(this.wallet).call()));
        
        //let epochForm = ethers.utils.parseEther(previous.toString())
        totalPoints = Number(this.web3.utils.fromWei(await this.shineStakeContract.methods.getTotalPointsEpoch(previous).call()));
       
        lastWeekRewards = totalPoints * rewardsPerPoint;
        
      }

      return {
        totalClaimed: totalClaimed,
        totalPoints:totalPoints,
        claimable:claimable,
        lastWeekRewards:lastWeekRewards
      }
    }
    catch(err){
      console.log(err)
    }
  }

  async getApyCalcs(price,totalStaked, bnbprice, lastWeekRewards){
    try{
      console.log(price)
      console.log(totalStaked)
      console.log(bnbprice)
      console.log(lastWeekRewards)
      //Calc Apr and Apy
      let TLV = price * totalStaked;
      let bnb = (price/bnbprice);
      //let bnb = 329;
      let silverRewardPoint = await this.shineStakeContract.methods.silverReward().call();

      let goldRewardPoint = await this.shineStakeContract.methods.goldReward().call();

      let diamondRewardPoint = await this.shineStakeContract.methods.diamondReward().call();
      let totalRewardPoints = Number(silverRewardPoint.toString()) + Number(goldRewardPoint.toString()) + Number(diamondRewardPoint.toString());

      let silverApr =lastWeekRewards === 0 ? 0 :((((lastWeekRewards*bnbprice)*(Number(silverRewardPoint.toString())/totalRewardPoints))/(totalStaked*price)))*52;

      let goldApr =lastWeekRewards === 0 ? 0 :(((lastWeekRewards*bnbprice)*((Number(goldRewardPoint.toString())+Number(silverRewardPoint.toString()))/totalRewardPoints))/(totalStaked*price))*52;
      
      let diamondApr = lastWeekRewards === 0 ? 0 :(((lastWeekRewards*bnbprice)*((Number(goldRewardPoint.toString())+Number(silverRewardPoint.toString())+Number(diamondRewardPoint.toString()))/totalRewardPoints))/(totalStaked*price))*52;
      
      let silverApy = Math.pow((1 + (silverApr/52)), 52) - 1;
      let goldApy = Math.pow((1 + (goldApr/52)), 52) - 1;
      let diamondApy = Math.pow((1 + (diamondApr/52)), 52) - 1;

      return {
        TLV,
        silverApr,
        goldApr,
        diamondApr,
        silverApy,
        goldApy,
        diamondApy
      }
    }
    catch(err){
      console.log(err);
    }
  }

  

  /*async getStakeData(shinecontract, contract){
    try{
      let ethers = Moralis.web3Library;
      let address = Moralis.account;
      await this.getProvider();
      //const shinecontract = new ethers.Contract( environment.shineContract, abi);
      let shineRead = await shinecontract.connect( this.provider ) as any;
      let balance = await shineRead.balanceOf(address);
      this.shineBalance = Number(ethers.utils.formatEther(balance));
      //const contract = new ethers.Contract( environment.stakeContract, stakeabi);
      const signer = this.provider.getSigner();

      let shineSign = await contract.connect( signer ) as any;

      //Gets User Data
      let balances = await shineSign.getUserStakeData(address);
      this.silver = Number(ethers.utils.formatEther(balances.silverAmount));
      let isOwner = await shineSign.owner();
      this.owner = isOwner.toLowerCase() === address.toLowerCase();
      this.gold = Number(ethers.utils.formatEther(balances.goldAmount));
      this.diamond = Number(ethers.utils.formatEther(balances.diamondAmount));
      if(this.silver > 0){
        let silverTime = await shineSign.getUnlockTime(address, 'SILVER');
        this.silverUnstakable = new Date(Number(silverTime.toString()) * 1000);
        this.silverDaysRem = moment(this.silverUnstakable).diff(moment(new Date()), 'days');
      }
      if(this.gold > 0){
        let goldTime = await shineSign.getUnlockTime(address, 'GOLD');
        this.goldUnstakable = new Date(Number(goldTime.toString()) * 1000);
        this.goldDaysRem = moment(this.goldUnstakable).diff(moment(new Date()), 'days');
      }
      if(this.diamond > 0){
        let diamondTime= await shineSign.getUnlockTime(address, 'DIAMOND');
        this.diamondUnstakable = new Date(Number(diamondTime.toString()) * 1000);
        this.diamondDaysRem = moment(this.diamondUnstakable).diff(moment(new Date()), 'days');
      }
      let epochUn = await shineSign.getCurrentEpoch();
      let epoch = Number(epochUn.toString());
    
      //Total Staked Coins All Users
      let totalStakedUnformated = await shineSign.getTotalStaked();
      this.totalStaked = Number(ethers.utils.formatEther(totalStakedUnformated));
      if(this.totalStaked !== 0){
        let totalSilverStakedUnfor = await shineSign.totalSilverStaked();
        this.totalSilverStaked = Number(ethers.utils.formatEther(totalSilverStakedUnfor));
        
        let totalGoldStakedUnfor = await shineSign.totalGoldStaked();
        this.totalGoldStaked = Number(ethers.utils.formatEther(totalGoldStakedUnfor));
        
        let totalDiamondStakedUnfor = await shineSign.totalDiamondStaked();
        this.totalDiamondStaked = Number(ethers.utils.formatEther(totalDiamondStakedUnfor));
      }
      
      //Gets number of user currently staking by tier
      let totalSilverUn = await shineSign.getPoolStakers('SILVER')
      this.totalSilverStakers = Number(totalSilverUn.toString());
      let totalGoldUn = await shineSign.getPoolStakers('GOLD')
      this.totalGoldStakers = Number(totalGoldUn.toString());
      let totalDiamondUn = await shineSign.getPoolStakers('DIAMOND')
      this.totalDiamondStakers = Number(totalDiamondUn.toString());


      //User Claim Data
      let tClaim = await shineSign.totalClaimedRewardsUser(address);
      this.totalClaimed = Number(ethers.utils.formatEther(tClaim));
      let totalPoints = 0;
      let rewardsPerPoint = 0;
      let previous = epoch > 0 ? epoch - 1 : epoch;
 
      if(previous >= 0){
        let lastWeekRewardsUnfor = await shineSign.getRewardsPerPointEpoch(previous);
        rewardsPerPoint = Number(ethers.utils.formatUnits(lastWeekRewardsUnfor, 9));
        let claimB = await shineSign.getUserUnclaimedRewards(address);
        this.claimable = Number(ethers.utils.formatEther(claimB));
        let epochForm = ethers.utils.parseEther(previous.toString())
        let totalPointsUn = await shineSign.getTotalPointsEpoch(previous);
        totalPoints = Number(ethers.utils.formatUnits(totalPointsUn,9 ));
        this.lastWeekRewards = totalPoints * rewardsPerPoint;

      }
    
      //Calc Apr and Apy
      this.TLV = this.price * this.totalStaked;
      let bnb = (this.price/this.bnbprice);
      //let bnb = 329;
      let silverRewardPoint = await shineSign.silverReward();
 
      let goldRewardPoint = await shineSign.goldReward();

      let diamondRewardPoint = await shineSign.diamondReward();
      let totalRewardPoints = Number(silverRewardPoint.toString()) + Number(goldRewardPoint.toString()) + Number(diamondRewardPoint.toString());

      this.silverApr =this.lastWeekRewards === 0 ? 0 :((((this.lastWeekRewards*this.bnbprice)*(Number(silverRewardPoint.toString())/totalRewardPoints))/(this.totalStaked*this.price)))*52
      //this.silverApr = this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards*this.bnbprice)*(Number(silverRewardPoint.toString())/totalRewardPoints))/(this.totalStaked*this.price));
      //this.silverApr = this.lastWeekRewards === 0 ? 0 :((((this.lastWeekRewards * (Number(silverRewardPoint.toString())/totalRewardPoints)))/(this.totalSilverStaked*bnb)))*100;

      this.goldApr =this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards*this.bnbprice)*((Number(goldRewardPoint.toString())+Number(silverRewardPoint.toString()))/totalRewardPoints))/(this.totalStaked*this.price))*52;
      //this.goldApr = this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards * (Number(goldRewardPoint.toString())/totalRewardPoints)))/(this.totalGoldStaked*bnb));
      this.diamondApr = this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards*this.bnbprice)*((Number(goldRewardPoint.toString())+Number(silverRewardPoint.toString())+Number(diamondRewardPoint.toString()))/totalRewardPoints))/(this.totalStaked*this.price))*52;
      //this.diamondApr = this.lastWeekRewards === 0 ? 0 :(((this.lastWeekRewards * (Number(diamondRewardPoint.toString())/totalRewardPoints)))/(this.totalDiamondStaked*bnb));
      this.silverApy = Math.pow((1 + (this.silverApr/52)), 52) - 1;
      this.goldApy = Math.pow((1 + (this.goldApr/52)), 52) - 1;
      this.diamondApy = Math.pow((1 + (this.diamondApr/52)), 52) - 1;
      this.loadingStatus = ''
      this.clearModal();
    }
    catch(error){
      console.log(error)
      this.loadingStatus = 'Error Occured Getting Your Data. Please Refresh the page.';
      
    }
    
  }*/


  

}
