
export const environment = {
  production: true,
  moralisAppId:"3aiNivDwJfAPIaj00ZU8llNMISKKFku07cHxjvWb",
  moralisUrl:"https://hjjjmpwraerj.usemoralis.com:2053/server",
  endpoint:"0xbB78CD9d658201119a78395dE350e3F531c12273",
  token:"jdjaosiij28892SIAHI049923701CIGknlklajal",
  shineContract:"0xbB78CD9d658201119a78395dE350e3F531c12273",
  //shineContract:"0x057B694Ddaf2f244bF42e2eEE361DA2EBbFD4132",
  stakeContract:"0x1653B9aa9c9ce3aE976720b7B449bcEb281E1BFc",
  //stakeContract:"0xc1ad04C4606D25F3D6a9DBe379d8D238670a1Ff6",
  bnb:"0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
  tokenAddress:"0x648e8428e0104ec7d08667866a3568a72fe3898f",
  bsckey:"3ZBWW5XR6CWZ9GQ1SY42W8DJ16G37TUTMR",
  covkey:"ckey_776e8c121b374a7a822c55f1352"
};
