import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../services/api.service';
import Moralis from 'moralis';
import { environment } from '../../environments/environment.prod';
import { Router } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})


export class NavComponent implements OnInit {
  


  constructor(@Inject(DOCUMENT) private doc: Document, 
  private service:ApiService, private router: Router, private alert: AlertModule) { 
    
  }

  
  ngOnInit(): void {
      }
}
