import { Injectable, Inject } from '@angular/core';
//import { environment } from 'src/environments/environment';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as bcrypt from 'bcryptjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';
const Web3 = require('web3');
let web3 = new Web3('ws://localhost:8546');
@Injectable({
  providedIn: 'root'
})
export class ApiService {
 
  geckoURL:string ='https://api.coingecko.com/api/v3';
  constructor(private http: HttpClient, @Inject(DOCUMENT) private doc: Document) { 
    
  }

  getCoinData(){
    return this.http.get("https://api.coingecko.com/api/v3/coins/shinemine/market_chart?vs_currency=usd&days=14&interval=daily");
  }

  getBNBCoinData(){
    return this.http.get("https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd").toPromise();
  }
  getShinePrice(){
    return this.http.get("https://api.coingecko.com/api/v3/simple/price?ids=shinemine&vs_currencies=usd").toPromise();
  }

  getCoinSupply(){
    return this.http.get("https://api.bscscan.com/api?module=stats&action=tokenCsupply&contractaddress="+environment.shineContract+"&apikey="+environment.bsckey).toPromise();
  }

  getTokenHolders(){
    
    return this.http.get("https://api.covalenthq.com/v1/"+56+"/tokens/"+environment.shineContract+"/token_holders/?page-size=1000&key="+environment.covkey);
  }
  
}
